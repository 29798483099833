import VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkinComponent from '@wix/thunderbolt-elements/src/components/VerticalAnchorsMenu/viewer/skinComps/VerticalAnchorsMenuLinkedNoTextSkin/VerticalAnchorsMenuLinkedNoTextSkin.skin';
import VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkinController from '@wix/thunderbolt-elements/src/components/VerticalAnchorsMenu/viewer/VerticalAnchorsMenu.controller';


const VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkin = {
  component: VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkinComponent,
  controller: VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkinController
};


export const components = {
  ['VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkin']: VerticalAnchorsMenu_VerticalAnchorsMenuLinkedNoTextSkin
};

